import Vue from 'vue';
import VueRouter from 'vue-router';
import { tokenExpressInTime } from '../tools/utils';
import { Message } from 'element-ui';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/',
    redirect: 'login',
    component: () => import('@/views/Login.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    redirect: '/followdata',
    meta: {
      name: '主页',
    },
    children: [
      {
        path: '/followdata',
        component: () => import('@/views/FollowUpData/FollowData.vue'),
      },
    ],
  },
];

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const router = new VueRouter({
  routes,
});
router.beforeEach((to, from, next) => {
  //如果访问的是登录页面直接放行
  if (to.path == '/login') return next();
  //获取储存的token值
  const token = window.sessionStorage.getItem('token');
  if (!token) {
    next('/login');
  } else {
    next();
  }
  //判断token是否过期
  if (tokenExpressInTime() && token) {
    Message({
      type: 'error',
      center: true,
      message: '登录失效，请重新登录',
      duration: 2000,
    });
    next('/login');
  } else {
    next();
  }
});
export default router;
