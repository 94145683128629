import router from '../router';
import moment from 'moment';
import Vue from 'vue';

export default {
  $toInt(str) {
    return parseInt(str);
  },
};

//简单封装路由跳转
export function toPages(name, query) {
  if (query) {
    router.push({ name, query });
  } else {
    router.push({ name });
  }
}

//Vue全局时间过滤器过滤器
Vue.filter('dateFormat', function (originDate, format) {
  return moment(originDate).format(format);
});
Vue.filter('dateSpecialFormat', function (originDate, format) {
  // return moment(originDate, [format, moment.ISO_8601])
  return moment(originDate).format(format);
});

//时间处理
export function timeMode(time, symbol) {
  symbol = symbol ? symbol : '-';
  let dates = new Date(time);
  let year = String(dates.getFullYear());
  let month =
    String(dates.getMonth() + 1) < 10
      ? '0' + String(dates.getMonth() + 1)
      : String(dates.getMonth() + 1);
  let date =
    String(dates.getDate()) < 10
      ? '0' + String(dates.getDate())
      : String(dates.getDate());
  let hour =
    String(dates.getHours()) < 10
      ? '0' + String(dates.getHours())
      : String(dates.getHours());
  let minutes =
    String(dates.getMinutes()) < 10
      ? '0' + String(dates.getMinutes())
      : String(dates.getMinutes());
  let seconds =
    String(dates.getSeconds()) < 10
      ? '0' + String(dates.getSeconds())
      : String(dates.getSeconds());
  let datestr = year + symbol + month + symbol + date;
  let dateMonth = month + symbol + date;
  let dateYear = year;
  let getStartTime = year + symbol + month + symbol + date + ' ' + '00:00';
  let getEndTime = year + symbol + month + symbol + date + ' ' + '24:59';
  let dateMin =
    year +
    symbol +
    month +
    symbol +
    date +
    ' ' +
    hour +
    ':' +
    minutes +
    ':' +
    seconds;
  let dateMinu =
    year + symbol + month + symbol + date + ' ' + hour + ':' + minutes;
  return {
    datestr,
    dateMonth,
    dateYear,
    getStartTime,
    getEndTime,
    dateMin,
    dateMinu,
  };
}

//计算年龄
export function jsGetAge(strBirthday) {
  var returnAge;
  var strBirthdayArr = strBirthday.split('-');
  var birthYear = strBirthdayArr[0];
  var birthMonth = strBirthdayArr[1];
  var birthDay = strBirthdayArr[2];

  var d = new Date();
  var nowYear = d.getYear();
  var nowMonth = d.getMonth() + 1;
  var nowDay = d.getDate();

  if (nowYear == birthYear) {
    returnAge = 0; //同年 则为0岁
  } else {
    var ageDiff = nowYear - birthYear; //年之差
    if (ageDiff > 0) {
      if (nowMonth == birthMonth) {
        var dayDiff = nowDay - birthDay; //日之差
        if (dayDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      } else {
        var monthDiff = nowMonth - birthMonth; //月之差
        if (monthDiff < 0) {
          returnAge = ageDiff - 1;
        } else {
          returnAge = ageDiff;
        }
      }
    } else {
      returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
    }
  }

  return returnAge; //返回周岁年龄
}

//防抖debounce代码：
function debounce(fn, delay) {
  var timer; // 维护一个 timer
  return function () {
    var _this = this; // 取debounce执行作用域的this
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(function () {
      fn.apply(_this); // 用apply指向调用debounce的对象，相当于_this.fn(args);
    }, delay);
  };
}

//监听token过期的方法
export function tokenExpressInTime() {
  let date = new Date();
  let tokenTime = localStorage.getItem('tokenTime');
  // 当前时间减去获取本地过期时间的值是否大于0   大于0为过期返回true 否则返回false
  return date.getTime() - Number(tokenTime) > 0 ? true : false;
}
