import axios from 'axios';
// import { config } from '../config';
import qs from 'qs';

const CancelToken = axios.CancelToken;

axios.defaults.baseURL = process.env.VUE_APP_baseUrl;
// axios.defaults.timeout = 500000;
axios.defaults.headers = {
  'Content-Type': 'application/x-www-form-urlencoded',
};

axios.interceptors.request.use(function (config) {
  const token = window.sessionStorage.getItem('token');
  if (token) {
    // console.log(`Bearer ${token}`)
    config.headers['Authorization'] = `Bearer ${token}`;
  }
  if (config.custom !== 'noqs') {
    config['data'] = qs.stringify(config['data']);
  }
  return config;
});

export function httpReq(ins, conf) {
  let _ins = axios;
  let cancel;
  if (typeof ins === 'object') {
    conf = ins;
  } else {
    _ins = ins;
    conf = conf || {};
  }
  conf.cancelToken = new CancelToken(function executor(c) {
    cancel = c;
  });
  let loading;
  if (conf.loadingConf) {
    loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
    });
  }
  const p = _ins(conf)
    .then(r => {
      conf.loadingConf && loading.close();
      // 业务逻辑成功
      if (r.data && r.data.code === '0000000000') {
        return r.data;
      } else {
        return Promise.reject(r.data);
      }
    })
    .catch(e => {
      // 可能是上一层reject的, 判断一下是业务逻辑失败, 还是网络出错、服务器异常
      conf.loadingConf && loading.close();
      if (e.code) {
        // 意味着是业务逻辑失败
        return Promise.reject(e);
      } else {
        return Promise.reject({
          msg: e.message,
        });
      }
    });
  p.cancel = cancel;
  return p;
}
