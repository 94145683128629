/* eslint-disable */
import { Message, Loading } from 'element-ui';
import router from '../router';
import { httpReq } from '../http/req';
const loginAbout = {
  namespaced: true,
  actions: {
    // 登录
    login({ state, commit, dispatch, rootState }, loginForm) {
      let loadingInstance = Loading.service({
        lock: true,
        text: '登录中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      httpReq({
        url: '/auth/login',
        data: loginForm,
        method: 'post',
      })
        .then(res => {
          console.log(res);
          const { token, ...info } = res.data;
          console.log(token);
          console.log(info);
          window.sessionStorage.setItem('token', token);
          const userInfo = JSON.stringify(info);
          window.sessionStorage.setItem('userInfo', userInfo);
          let date = new Date().getTime() + 10 * 3600 * 1000;
          localStorage.setItem('tokenTime', date);
          router.push('/home');
          loadingInstance.close();
          Message.success('登录成功');
        })
        .catch(err => {
          console.log(err);
          Message.error(`登录失败:${err.msg}`);
          loadingInstance.close();
        });
    },
    //登出事件
    loginOut({ state, commit, dispatch, rootState }) {
      window.sessionStorage.clear();
      router.push('/login');
      window.localStorage.clear();
    },
  },
  mutations: {},
  state: {},
  getters: {},
};
export default loginAbout;
